export const Holiday = [
  {
    tittle: "Search",
    path: "/holidaypackage",
  },
  // {
  //   tittle: "Create Holiday Package",
  //   path: "/CreateHolidayPackage",
  //   name: "Create Holiday Package",
  // },
  {
    tittle: "Create Holiday Package",
    path: "/CreateHolidayPackagenew",
    name: "Create Holiday Packagenew",
  },
  {
    tittle: "Package List",
    path: "/PackagesList",
    name: "Create Holiday Packagenew images",
  },
];
